import * as React from "react"
import ContentPageLayout from "../../components/ContentPageLayout"
import SCButton from "../../components/buttons/SCButton"
import { navigate } from "gatsby"
import { Doughnut } from "react-chartjs-2"
import config from "../../config"
import UpdateMessage from "../../components/UpdateMessage"
import CommunityContentLayout from "../../components/CommunityContentLayout"
import { StaticImage } from "gatsby-plugin-image"
import AvatarEdit from "../../components/social/AvatarEdit"
import firebase from "firebase"

// Importaciones necesarias de Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js"
import PerfilForm from "../../components/modals/PerfilFormModal"

// Registro de los componentes necesarios para Chart.js
ChartJS.register(CategoryScale, LinearScale, ArcElement, Tooltip, Legend)
const orderedCategories = ["Fun", "Sport", "Balance", "Body", "Power"]

function orderPercentages(percentages) {
  const orderedPercentages = {}

  orderedCategories.forEach(category => {
    if (percentages[category]) {
      orderedPercentages[category] = percentages[category]
    }
  })

  return orderedPercentages
}

const getCategoryClass = category => {
  // Aquí puedes asignar la clase de color según la categoría
  switch (category) {
    case "Fun":
      return "#4dfbd4" // Reemplaza "text-fun-color" con la clase CSS adecuada
    case "Sport":
      return "#08675a" // Reemplaza "text-sport-color" con la clase CSS adecuada
    case "Balance":
      return "#00a48b" // Reemplaza "text-balance-color" con la clase CSS adecuada
    case "Body":
      return "#19e8c0" // Reemplaza "text-body-color" con la clase CSS adecuada
    case "Power":
      return "#00342f" // Reemplaza "text-power-color" con la clase CSS adecuada
    default:
      return "" // Puedes manejar el caso por defecto según tus necesidades
  }
}

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      displayColors: false,
      bodyFont: {
        size: 12,
      },
      titleFont: {
        size: 13,
      },
      callbacks: {
        label: function (context) {
          const label = context.label;
          const value = context.parsed;
          if (value === 0) {
            // No mostrar el tooltip para elementos con valor 0%
            return null;
          }
          const customLabel = getDescriptionForCategory(label);

          const words = customLabel.split(" ");
          const lines = [];
          let currentLine = "";

          words.forEach(word => {
            if ((currentLine + word).length <= 25) {
              currentLine += word + " ";
            } else {
              lines.push(currentLine.trim());
              currentLine = word + " ";
            }
          });
          if (currentLine) {
            lines.push(currentLine.trim());
          }

          return lines;
        },
      },
      filter: function (tooltipItems) {
        // Ocultar el tooltip completo para elementos con valor 0%
        return tooltipItems.parsed !== 0;
      },
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
    },
  },
};


function getDescriptionForCategory(category) {
  // Descripciones de ejemplo, reemplaza con tus propias descripciones
  const descriptions = {
    Fun: "Personas a las que les encanta divertirse mientras entrenan, mejorar su coordinación, prefieren actividades cardiovasculares y clases.",
    Sport:
      "Personas que desean conseguir el máximo rendimiento de su entrenamiento al aire libre y gimnasio para mejorar su desempeño.",
    Balance:
      "Personas a las que les gusta conectar cuerpo y mente, relajación, fuerza consciente y equilibrio.",
    Body: "Personas que se preocupan por la estética corporal, esfuerzo físico, tonificar y moldear los músculos, así como mejorar la resistencia muscular.",
    Power:
      "Personas a las que les gustan los desafíos, fuerza, mejorar su resistencia cardiovascular, potencia y entrenamientos competitivos.",
  }
  return descriptions[category] || category
}

const profileUri = `${config.serverUri}/users`

const ProfilePage = ({ location }) => {
  const [formValues, setFormValues] = React.useState({
    id: "",
    nombre: "",
  })
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [showPreferences, setShowPreferences] = React.useState(false)
  const [isPremium, setIsPremium] = React.useState(false)

  const [error, setError] = React.useState("")
  const [chartData, setChartData] = React.useState(null)

  React.useEffect(() => {
    const user = firebase.auth().currentUser

    if (user) {
      fetch(profileUri + `?email=${user.email}`)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              const account = data[0]
              setIsPremium(account.suscription === "premium")
              setFormValues({
                id: account.id,
                nombre: account.nombre,
              })
              if (account.suscription) {
                setUserHasSuscription(account.suscription)
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      navigate("/")
    }

    firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        navigate("/")
      }
    })
  }, [])

  React.useEffect(() => {
    fetch(`${profileUri}/porcentajes-gustos/${formValues.id}`)
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            const percentages = data

            const orderedPercentages = orderPercentages(percentages)

            const chartData = {
              labels: orderedCategories,
              datasets: [
                {
                  data: orderedCategories.map(category =>
                    parseFloat(orderedPercentages[category].porcentaje)
                  ),
                  backgroundColor: orderedCategories.map(
                    category => orderedPercentages[category].color
                  ),
                },
              ],
            }

            setChartData(chartData)
          })
        } else {
          setChartData(null)
        }
      })
      .catch(error => {
        console.log(error)
        setChartData(null)
      })
  }, [formValues.id])

  const renderChartOrMessage = () => {
    if (chartData) {
      const todosCeros =
        Array.isArray(chartData.datasets[0].data) &&
        chartData.datasets[0].data.every(valor => valor === 0)

      if (todosCeros) {
        // Si todos los valores son cero, significa que el usuario no ha contestado el cuestionario
        return (
          <div className="text-center my-4">
            <p>Aún no has contestado la encuesta</p>
            <SCButton onClick={() => setShowPreferences(true)}>
              Contestar
            </SCButton>
          </div>
        )
      } else {
        return (
          <div className="my-4">
            <div className="flex justify-around">
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  position: "relative",
                }}
              >
                <Doughnut data={chartData} options={chartOptions} />
              </div>
              <div style={{ width: "200px" }}>
                <ul>
                  {chartData.labels.map((label, index) => (
                    <li
                      key={label}
                      className="flex justify-between gap-[100px]"
                    >
                      <div>
                        <span
                          className="mr-2"
                          style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor:
                              chartData.datasets[0].backgroundColor[index],
                          }}
                        >
                          {" "}
                        </span>
                        {label}:
                      </div>
                      <div>{chartData.datasets[0].data[index]}%</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="text-center">
              <p
                className="mb-1 text-lg text-bold"
                style={{
                  color: getCategoryClass(
                    getCategoryWithHighestPercentage(chartData)
                  ),
                }}
              >
                Eres {getCategoryWithHighestPercentage(chartData)}
              </p>
              <p className="mb-1 text-lg">
                Ahora podrás disfrutar de las mejores
              </p>
              <p className="text-lg">recomendaciones para ti</p>
            </div>
            <div className="w-full flex justify-center">
              <div
                className="bg-sc-primary rounded-full shadow-2xl p-2 text-sc-titles cursor-pointer w-max"
                onClick={() => setShowPreferences(true)}
              >
                <div className="text-center w-max">
                  Volver a hacer la encuesta
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      // Si chartData es nulo, no se puede determinar si se ha contestado el cuestionario
      return (
        <div className="text-center my-4">
          <p>Aún no has contestado la encuesta</p>
          <SCButton onClick={() => setShowPreferences(true)}>
            Contestar
          </SCButton>
        </div>
      )
    }
  }

  const getCategoryWithHighestPercentage = data => {
    const labels = data.labels
    const datasets = data.datasets[0].data

    const maxPercentage = Math.max(...datasets)
    const maxPercentageIndex = datasets.indexOf(maxPercentage)

    return labels[maxPercentageIndex]
  }

  return (
    <>
      <ContentPageLayout location={location}>
        <div>
          <div
            className="flex flex-col relative"
            style={{ height: 240, width: "100%" }}
          >
            <StaticImage
              alt="banner comunidad"
              src="../../images/banner_comunidad.png"
              objectFit="cover"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            ></StaticImage>
            <div className="absolute w-full p-4 flex-col justify-center text-center">
              <div className="md:hidden mb-4">
                <AvatarEdit
                  avatar={formValues.avatar}
                  id={formValues.id}
                  onUploadComplete={url => {
                    setFormValues({ ...formValues, avatar: url })
                  }}
                ></AvatarEdit>
              </div>

              <h2 className="text-white md:hidden">Cuenta premium</h2>
            </div>
          </div>
        </div>
        <CommunityContentLayout isPremium={isPremium}>
          <div className="font-sans">
            <h2 className="mb-4">Informes de gustos</h2>
            <p className="mb-1 text-center text-lg font-bold">
              Hola {formValues.nombre},
            </p>
            <p className="text-center text-lg">estos son tus resultados:</p>
            {renderChartOrMessage()}
            {error && <span style={{ color: "red" }}>{error}</span>}
          </div>
        </CommunityContentLayout>
      </ContentPageLayout>
      {userHasSuscription === "premium" ? (
        <PerfilForm
          show={showPreferences}
          ispwaComponent={true}
          onClose={() => {
            setShowPreferences(false)
          }}
        />
      ) : null}
    </>
  )
}

export default ProfilePage
